<template>
    <div class="mb-4 rounded">
        <div class="row">
            <div class="col-md-5 col-sm-12 m-3">
                <qr-code 
                    :text=route
                    error-level="H">
                </qr-code>
            </div>
            <div class="col-md-6 col-sm-12 m-2">
                <span class="info-name">Nomor / Number</span>
                <h4 class="subtitle">{{ participant.seq_num }}</h4>
                <span class="info-name">Nama / Name</span>
                <h4 class="subtitle">{{ participant.member.name }}</h4>
                <span class="info-name">Komunitas / Community</span>
                <h4 class="subtitle">{{ participant.community ? participant.community.name : participant.community_name }}</h4>
            </div>
        </div>
    </div>
</template>

<script scoped>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
    props: {
        participant: {
            type: Object
        },
        route: {
            type: String
        }
    },
    components: {
        'qr-code': VueQRCodeComponent,
    }
}
</script>

<style scoped>
.info-box-icon {
    width: 9rem;
}
</style>