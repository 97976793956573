<template>
  <div>
    <div class="row mb-2">
      <div class="col-3 offset-9">
        <input type="text" placeholder="Search" v-model="search" class="form-control" @change="getLeaderBoard()">
      </div>
    </div>
    <table class="table table-striped table-bordered table-responsive">
      <thead>
        <tr>
          <td>#</td>
          <td>No. Peserta</td>
          <td>Nama</td>
          <td>Total Distance</td>
          <td>Total Moving Time</td>
          <td>Total Elapsed Time</td>
          <td>Total Elevation Gain</td>
        </tr>
      </thead>
      <div v-if="isLoading" class="d-flex align-items-center">
        <strong>Loading...</strong>
        <div
          class="spinner-border ml-auto"
          role="status"
          aria-hidden="true"
        ></div>
      </div>
      <tbody v-else>
        <tr v-for="(parti, index) in participants" :key="index">
          <td>#{{ index + 1 + ((currentPage - 1) * perPage) }}</td>
          <td>{{ parti.seq_num }}</td>
          <td>{{ parti.member.name }}</td>
          <td>{{ parti.valid_total_distance }} km</td>
          <td>{{ parti.valid_total_moving_time }}</td>
          <td>{{ parti.valid_total_elapsed_time }}</td>
          <td>{{ parti.valid_total_elevation_gain }} m</td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
      @change="handlePageChange"
    ></b-pagination>
  </div>
</template>

<script>
import axios from "axios";
import {BPagination} from 'bootstrap-vue';

export default {
  name: "LeaderBoard",
  props: {
    event: {
      type: Object,
    },
    authToken: {
      type: String,
    },
  },
  components: {
    BPagination,
  },
  data() {
    return {
      participants: [],
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      rows: 0,
      search: "",
    };
  },
  created() {
    this.getLeaderBoard();
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value;
      this.getLeaderBoard();
    },
    getLeaderBoard() {
      if (!this.event.display_strava) return
      this.isLoading = true;
      axios
        .get(
          `/api/v2/events/${this.event.id}/leaderboard.json?search=${this.search}&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.authToken}`,
            },
          }
        )
        .then((resp) => {
          this.participants = resp.data.data;
          this.currentPage = resp.data.pagination.current_page;
          this.rows = resp.data.pagination.rows;
          this.perPage = resp.data.pagination.per_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("Gagal mengambil list peserta");
          this.isLoading = false;
        });
    },
  },
};
</script>