<template>
  <div>
    <div class="row mb-2">
      <div class="col-3 offset-9">
        <input type="text" placeholder="Search" v-model="search" class="form-control" @change="getParticipants()">
      </div>
    </div>
    <table
      id="participant-table"
      class="table table-striped table-bordered table-responsive"
    >
      <thead>
        <tr>
          <th>Nomor</th>
          <th>Nama</th>
          <th>E-mail</th>
          <th>Tel/WA</th>
          <th>Komunitas</th>
          <th>Kategori</th>
          <th v-if="event.has_jersey">Jersey</th>
          <th>Status</th>
          <th>Invoice</th>
          <th>Action</th>
        </tr>
      </thead>
      <div v-if="isLoading" class="d-flex align-items-center">
        <strong>Loading...</strong>
        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
      </div>
      <tbody v-else>
        <tr v-for="parti in participants" :key="parti.id">
          <td>{{ parti.seq_num }}</td>
          <td>{{ parti.member.name }}</td>
          <td>{{ parti.member.email }}</td>
          <td>{{ parti.member.phone }}</td>
          <td>
            {{ parti.community ? parti.community.name : parti.community_name }}
          </td>
          <td>
            {{ parti.event_category ? parti.event_category.name : '' }}
          </td>
          <td v-if="event.has_jersey">{{ parti.size_jersey ? parti.size_jersey.split(' | ')[0] : '-' }}</td>
          <td>{{ parti.is_received_pack ? 'Sudah Ambil' : 'Belum Ambil' }}</td>
          <td>
            {{ parti.order_detail ? parti.order_detail.order.order_code : '-' }}
          </td>
          <td>
            <div v-if="!parti.is_received_pack" class="btn-group">
              <button
                class="btn btn-info btn-sm dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Ubah
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  data-toggle="modal"
                  data-target="#modalPeserta"
                  href="#participants"
                  @click="setParticipant(parti)"
                  >Data Peserta</a
                >
                <a
                  class="dropdown-item"
                  data-toggle="modal"
                  data-target="#modalRidepack"
                  href="#"
                  @click="setParticipant(parti)"
                  >Terima Ridepack</a
                >
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="`https://api.whatsapp.com/send/?phone=62${parti.member.phone}&text=Halo ${parti.member.name}, silahkan mengambil ridepack ${@event.name} Anda sesuai waktu yang telah ditentukan.`"
                  >Kirim Notif WA</a
                >
              </div>
            </div>
            <p v-else class="text-success">Sudah Diterima</p>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
      @change="handlePageChange"
    ></b-pagination>
  </div>
</template>

<script>
import axios from "axios";
import {BPagination} from 'bootstrap-vue';

export default {
  props: {
    sizes: {
      type: Array,
    },
    event: {
      type: Object,
    },
    authToken: {
      type: String,
    },
  },
  components: {
    BPagination,
  },
  data() {
    return {
      participants: [],
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      rows: 0,
      search: "",
    };
  },
  async created() {
    this.getParticipants()
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value;
      this.getParticipants();
    },
    async getParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/v2/events/${this.event.id}/participants.json?search=${this.search}&page=${this.currentPage}`, {
          headers: {
            Authorization:
              `Bearer ${this.authToken}`,
          },
        })
        .then((resp) => {
          this.participants = resp.data.data;
          this.currentPage = resp.data.pagination.current_page;
          this.rows = resp.data.pagination.rows;
          this.perPage = resp.data.pagination.per_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("Gagal mengambil list peserta");
          this.isLoading = false;
        });
    },
    setParticipant(parti) {
      this.$emit('set-participant', parti)
    },
    receivePack(parti) {
      this.$emit('receive-pack', parti)
    }
  }
};
</script>