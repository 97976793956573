<template>
  <div>
    <dl class="row">
      <dt class="col-sm-4">Nama</dt>
      <dd class="col-sm-8">{{ order.member.name }}</dd>
      <dt class="col-sm-4">e-mail</dt>
      <dd class="col-sm-8">{{ order.member.email }}</dd>
      <dt class="col-sm-4">Telepon</dt>
      <dd class="col-sm-8">{{ order.member.phone ? order.member.phone : '-' }}</dd>
      <dt class="col-sm-4">Nominal</dt>
      <dd class="col-sm-8">Rp {{ thousands(order.total_amount ? order.total_amount : order.total + order.uniq_cost) }}</dd>
      <dt class="col-sm-4">Status</dt>
      <dd class="col-sm-8">{{ order.status }}</dd>
      <dt class="col-sm-4">Tanggal Jatuh Tempo</dt>
      <dd class="col-sm-8">{{ toDate(order.expired_at) }}</dd>
    </dl>
    <table class="table">
      <thead>
        <tr>
          <th>Peserta</th>
          <th>Komunitas</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in order.order_details" :key="item.id">
          <td>{{ item.member.name }}</td>
          <td>{{ item.community ? item.community.name : item.community_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('id')

export default {
  props: {
    order: {
      type: Object,
    },
  },
  methods: {
    thousands(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    toDate (date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    }
  }
};
</script>