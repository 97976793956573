var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("dl", { staticClass: "row" }, [
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Nama")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.participant.member.name)),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Email")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.participant.member.email)),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Telepon / WA")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.participant.member.phone)),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Komunitas")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(
          _vm._s(
            _vm.participant.community
              ? _vm.participant.community.name
              : _vm.participant.community_name
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "label required", attrs: { for: "" } }, [
        _vm._v("Nama"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.participant.member.name,
            expression: "participant.member.name",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text", required: "" },
        domProps: { value: _vm.participant.member.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.participant.member, "name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _vm.participant.bike_id
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "label required" }, [
            _vm._v("Ukuran / Kategori Ban"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.participant.wheel_size,
                  expression: "participant.wheel_size",
                },
              ],
              staticClass: "custom-select",
              attrs: { required: "" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.participant,
                    "wheel_size",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { disabled: "" } }, [
                _vm._v("Pilih Ukuran / Kategori Ban"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.properties.wheel_size, function (size, idx) {
                return _c("option", { key: idx, domProps: { value: size } }, [
                  _vm._v("\n        " + _vm._s(size) + "\n      "),
                ])
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "label required" }, [_vm._v("Ukuran Jersey")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.participant.size_jersey,
              expression: "participant.size_jersey",
            },
          ],
          staticClass: "custom-select",
          attrs: { required: "" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.participant,
                "size_jersey",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v("Pilih Ukuran Jersey"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.sizes, function (size, idx) {
            return _c(
              "option",
              {
                key: idx,
                domProps: {
                  value: size,
                  selected: size === _vm.participant.size_jersey,
                },
              },
              [_vm._v("\n        " + _vm._s(size) + "\n      ")]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }