<template>
  <div>
    <dl class="row">
      <dt class="col-sm-4">Nama</dt>
      <dd class="col-sm-8">{{ participant.member.name }}</dd>
      <dt class="col-sm-4">Email</dt>
      <dd class="col-sm-8">{{ participant.member.email }}</dd>
      <dt class="col-sm-4">Telepon / WA</dt>
      <dd class="col-sm-8">{{ participant.member.phone }}</dd>
      <dt class="col-sm-4">Komunitas</dt>
      <dd class="col-sm-8">{{ participant.community ? participant.community.name : participant.community_name }}</dd>
    </dl>
    <div class="form-group">
      <label for="" class="label required">Nama</label>
      <input type="text" class="form-control" required v-model="participant.member.name">
    </div>
    <div v-if="participant.bike_id" class="form-group">
      <label class="label required">Ukuran / Kategori Ban</label>
      <select v-model="participant.wheel_size" class="custom-select" required>
        <option disabled>Pilih Ukuran / Kategori Ban</option>
        <option
          v-for="(size, idx) in properties.wheel_size"
          :key="idx"
          :value="size"
        >
          {{ size }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="label required">Ukuran Jersey</label>
      <select v-model="participant.size_jersey" class="custom-select" required>
        <option disabled>Pilih Ukuran Jersey</option>
        <option
          v-for="(size, idx) in sizes"
          :key="idx"
          :value="size"
          :selected="size === participant.size_jersey"
        >
          {{ size }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  props: {
    participant: {
      type: Object,
    },
    sizes: {
      type: Array,
    }
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
  computed: {
    properties() {
      return JSON.parse(this.participant.bike.properties)
    }
  }
};
</script>