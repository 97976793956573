var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-3 offset-9" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Search" },
            domProps: { value: _vm.search },
            on: {
              change: function ($event) {
                return _vm.getLeaderBoard()
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table table-striped table-bordered table-responsive" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("strong", [_vm._v("Loading...")]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "spinner-border ml-auto",
                  attrs: { role: "status", "aria-hidden": "true" },
                }),
              ])
            : _c(
                "tbody",
                _vm._l(_vm.participants, function (parti, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _vm._v(
                        "#" +
                          _vm._s(
                            index + 1 + (_vm.currentPage - 1) * _vm.perPage
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(parti.seq_num))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(parti.member.name))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(parti.valid_total_distance) + " km"),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(parti.valid_total_moving_time))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(parti.valid_total_elapsed_time))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(parti.valid_total_elevation_gain) + " m"),
                    ]),
                  ])
                }),
                0
              ),
        ]
      ),
      _vm._v(" "),
      _c("b-pagination", {
        attrs: {
          "total-rows": _vm.rows,
          "per-page": _vm.perPage,
          align: "center",
        },
        on: { change: _vm.handlePageChange },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("#")]),
        _vm._v(" "),
        _c("td", [_vm._v("No. Peserta")]),
        _vm._v(" "),
        _c("td", [_vm._v("Nama")]),
        _vm._v(" "),
        _c("td", [_vm._v("Total Distance")]),
        _vm._v(" "),
        _c("td", [_vm._v("Total Moving Time")]),
        _vm._v(" "),
        _c("td", [_vm._v("Total Elapsed Time")]),
        _vm._v(" "),
        _c("td", [_vm._v("Total Elevation Gain")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }