var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4 rounded" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-5 col-sm-12 m-3" },
        [_c("qr-code", { attrs: { text: _vm.route, "error-level": "H" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-sm-12 m-2" }, [
        _c("span", { staticClass: "info-name" }, [_vm._v("Nomor / Number")]),
        _vm._v(" "),
        _c("h4", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.participant.seq_num)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "info-name" }, [_vm._v("Nama / Name")]),
        _vm._v(" "),
        _c("h4", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.participant.member.name)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "info-name" }, [
          _vm._v("Komunitas / Community"),
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "subtitle" }, [
          _vm._v(
            _vm._s(
              _vm.participant.community
                ? _vm.participant.community.name
                : _vm.participant.community_name
            )
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }