var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("dl", { staticClass: "row" }, [
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Nama")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.order.member.name)),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("e-mail")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.order.member.email)),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Telepon")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.order.member.phone ? _vm.order.member.phone : "-")),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Nominal")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(
          "Rp " +
            _vm._s(
              _vm.thousands(
                _vm.order.total_amount
                  ? _vm.order.total_amount
                  : _vm.order.total + _vm.order.uniq_cost
              )
            )
        ),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [_vm._v(_vm._s(_vm.order.status))]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-sm-4" }, [_vm._v("Tanggal Jatuh Tempo")]),
      _vm._v(" "),
      _c("dd", { staticClass: "col-sm-8" }, [
        _vm._v(_vm._s(_vm.toDate(_vm.order.expired_at))),
      ]),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.order.order_details, function (item) {
          return _c("tr", { key: item.id }, [
            _c("td", [_vm._v(_vm._s(item.member.name))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  item.community ? item.community.name : item.community_name
                )
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Peserta")]),
        _vm._v(" "),
        _c("th", [_vm._v("Komunitas")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }