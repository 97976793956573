import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import moment from 'moment'
moment.locale('id')
import setupCSRFToken from "../../setupCSRFToken"
setupCSRFToken()
import InvoiceDetail from '../../components/events/invoice-detail.vue'
import ParticipantDetail from '../../components/events/participant-detail.vue'
import ParticipantList from '../../components/events/participant-list.vue'
import ETicket from '../../components/events/e-ticket.vue'
import LeaderBoard from '../../components/events/leader-board.vue'

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        el: "#show-event",
        components: {
            InvoiceDetail,
            ParticipantDetail,
            ParticipantList,
            ETicket,
            LeaderBoard,
        },
        data: {
            order: null,
            sizes: [
                'XXS',
                'XS',
                'S',
                'M',
                'L',
                'XL',
                'XXL',
                '3XL',
                '4XL',
                '5XL'
            ],
            paidAmount: null,
            participant: null,
            participants: [],
        },
        methods: {
            setInvoice(order) {
                this.order = order
                this.paidAmount = order.total_amount ? order.total_amount : order.total + order.uniq_cost
            },
            setParticipant(parti) {
                this.participant = parti
            },
            thousands(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            toDate (date) {
                return moment(date).format('DD/MM/YYYY HH:mm')
            },
            setToPaid() {
                axios.put(`/invoices/${this.order.id}/paid`, { paid_amount: this.paidAmount }).then(resp => {
                    if (resp.data.status === "success") {
                        alert('Status berhasil diubah mernjadi terbayar/paid untuk invoice ' + this.order.order_code + '!')
                        location.reload()
                    } else {
                        alert("Perubahan status gagal!")
                    }
                }).catch(err => {
                    alert("Gagal merubah status!")
                })
                location.reload();
            },
            sendInvoice(order) {
                axios.post(`/invoices/${order.id}/resend`).then(resp => {
                    if (resp.data.status === "success") {
                        alert('Berhasil mengirimkan email untuk invoice ' + order.order_code + '!')
                    } else {
                        alert(resp.data.message)
                    }
                }).catch(err => {
                    alert("Email tidak dapat terkirim!")
                })
            },
            receivePack(participant) {
                axios.post(`/participants/${participant.id}/receive-pack`).then(resp => {
                    if (resp.data.status === "success") {
                        alert('Berhasil terima ridepack nomor peserta' + participant.seq_num + '!')
                        location.reload()
                    } else {
                        alert("Gagal terima ridepack!")
                    }
                }).catch(err => {
                    alert("Gagal menerima ridepack!")
                })
            },
            sendLink(eventId, quotaId, member, url) {
                axios.post(`/events/${eventId}/send-link`, { member_id: member.id, url: url, quota_id: quotaId }).then(resp => {
                    if (resp.data.status === "success") {
                        alert(`Berhasil mengirimkan email ke ${member.email}. Silahkan info ke PIC terkait.`)
                    } else {
                        alert("Gagal mengirimkan link!")
                    }
                }).catch(err => {
                    alert("Gagal mengirimm link!")
                })
            },
            updateJersey(event, participant) {
                axios.post(`/participants/${participant.id}/update-jersey`, { size_jersey: event.target.value }).then(resp => {
                    console.log(resp);
                    if (resp.data.status === "success") {
                        alert(`Peserta dengan nomor ${participant.seq_num} telah berhasil merubah ukuran jersey ke ${event.target.value}`)
                        location.reload()
                    } else {
                        alert("Gagal ubah ukuran jersey!")
                    }
                }).catch(err => {
                    alert("Gagal merubah ukuran jersey!")
                })
            },
            updateParticipant() {
                axios.put(`/participants/${this.participant.id}`, this.participant).then(resp => {
                    console.log(resp.data.status);
                    if (resp.data.status === "success") {
                        alert(`Peserta dengan nomor ${this.participant.seq_num} telah berhasil diubah`)
                        location.reload()
                    } else {
                        alert("Gagal ubah data peserta!")
                    }
                }).catch(err => {
                    alert("Terjadi kesalahan saat merubah data peserta!")
                })
            },
            sendRidepackNotif(participant) {
                axios.post(`/participants/${participant.id}/ridepack-wa-notif`).then(resp => {
                    console.log(resp);
                    if (resp.data.status === "success") {
                        alert(`Notifikasi Whatsapp pengambilan ridepack berhasil dikirim!`)
                    } else {
                        alert("Gagal ubah ukuran jersey!")
                    }
                }).catch(err => {
                    alert("Gagal merubah ukuran jersey!")
                })
            }
        }
    })
})